.ant-collapse {
  .ant-collapse-content-box {
    padding: 0 !important;

    p {
      font-size: 1.125rem !important;
      font-weight: 400 !important;
      margin-top: 1.25rem !important;
    }
  }

  .ant-collapse-header {
    align-items: center !important;
    padding: 0 !important;
  }

  .ant-collapse-expand-icon img {
    width: 1.31rem !important;
    height: 0.59rem !important;
  }

  .ant-collapse-header-text,
  .ant-collapse-expand-icon {
    user-select: none;
  }
}

mux-player {
  aspect-ratio: 16 / 9;
  --media-object-fit: cover;
  --media-object-position: center;

  &::part(bottom playback-rate selectmenu) {
    display: none;
  }
}

.mux-container-height {
  height: calc(100% - 50px + 2.5rem);
}
