.tailoring-quiz-page {

  .quiz-header {

    .ant-progress,
    .ant-progress-outer,
    .ant-progress-inner,
    .ant-progress-bg {
      height: 1.25rem !important;
    }

    .ant-progress {
      margin: 0;
    }

    .ant-progress-bg {
      background: linear-gradient(90deg, #65B1EB 0%, #8BE9E3 90.63%);
    }

    .ant-progress-inner {
      background: #D9D9D9;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: black !important;
    border-color: black !important;
  }

  .ant-radio-inner:focus,
  .ant-radio-inner:hover,
  .ant-radio-inner:focus-visible {
    outline: none !important;
    border-color: black !important;
  }

  .ant-radio-inner {
    outline: none !important;
    border-color: black !important;
  }

  .ant-radio-wrapper-checked {
    font-weight: 700;
  }

  .ant-radio-wrapper {
    margin-bottom: 0.3rem;
  }

  .ant-radio-checked::after {
    border-color: black !important;
  }

  .ant-radio-input:focus-visible+.ant-radio-inner {
    outline: unset !important;
  }

  .ant-radio-group {
    @media screen and (max-width: 720px) {
      & {
        flex-direction: column;
      }
    }

    width: 100%;
    display: flex;

    .ant-radio-button-wrapper {
      width: 100%;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid black;
      font-size: 1.25rem;

      &:hover {
        color: black;
      }

      &:focus-visible {
        outline: none !important;
      }
    }
  }

  .ant-radio-button-wrapper-checked {
    color: black !important;
    font-weight: 700 !important;
    background-color: #F4F4F4 !important;

    &:hover {
      color: black !important;
    }

    &::before {
      background-color: #F4F4F4 !important;
    }
  }

  .ant-wave {
    color: black !important;
    box-sizing: unset !important;
  }

  .multi-select {
    label.active {
      background-color: #F4F4F4;
      font-weight: 700;
      border: 1px solid black;
    }
  }

}
