@import "./variables.scss";
@import "./base.scss";
@import "./pages/tailoring-quiz.scss";
@import "./pages/dashboard.scss";
@import "./pages/date-room.scss";

.cyan-gradient-bg-image {
  background-image: url('../assets/background-gradient.png');
  background-size: cover;
  // Todo: replace with below
  // background: linear-gradient(90deg, #65B1EB 0%, #8BE9E3 90.63%);
}
