@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  #root,
  body,
  html {
    height: 100%;
    box-sizing: border-box !important;
    font-size: 16px;
  }

  html,
  input,
  * {
    font-family: 'Nunito Sans', sans-serif !important;
  }

  button {
    -webkit-tap-highlight-color: transparent;
  }

  h1 {
    @apply text-3xl;
    font-weight: 700;
  }

  h2 {
    @apply text-2xl;
  }

  p {
    @apply text-xl;
  }

  a {
    color: #0A82AA !important;
    font-weight: 700 !important;
  }

  @media (max-width: 975px) {
    html {
      font-size: 15px !important;
    }
  }

  @media (max-width: 480px) {
    html {
      font-size: 14px !important;
    }
  }
}
