ul.ant-dropdown-menu-root {
  min-width: 14rem !important;
  padding: 2.5rem 1.88rem !important;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid rgba(0, 0, 0, 0.20) !important;
  border-radius: 0.625rem !important;

  margin-top: 2rem !important;

  & .ant-dropdown-menu-title-content {
    font-size: 1.25rem !important;
  }

  & .ant-dropdown-menu-item:not(:last-child) {
    margin-bottom: 1.88rem !important;
  }

  & .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  & .ant-dropdown-menu-item:hover {
    background-color: white !important;
  }
}

.how-it-works.ant-modal,
.before-joining.ant-modal {
  $modal-border-radius: 12px;

  width: 41rem;

  .ant-modal-content {
    padding: 0 !important;
    border-radius: $modal-border-radius;
  }

  .slick-dots li {
    width: 15px !important;
    height: 15px !important;
    border-radius: 100%;
    background-color: #D9D9D9;

    & button {
      width: 15px !important;
      height: 15px !important;
      border-radius: 100%;
      background-color: #D9D9D9;
    }

    &.slick-active {
      background-color: #1F2A2F;

      & button {
        background-color: #1F2A2F;
      }
    }
  }

  ul.slick-dots {
    justify-content: center;
    bottom: 3.4rem;
    max-width: auto !important;
  }

  .ant-carousel .slick-slider {
    position: static !important;
  }

  .slick-list {
    border-radius: $modal-border-radius;
  }
}

.send-partner-invite-modal,
.review-modal {
  .ant-modal-content {
    border-radius: 1.25rem;
    border: 1px solid rgba(0, 0, 0, 0.20);
    padding: 0 !important;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.35);
  }
}

html:has(.dates-list-page) {
  height: auto !important;
}

.card-details {
  padding-top: 2rem;
  padding-bottom: 2rem !important;

  .ant-modal-content {
    padding: 0;
    border-radius: 12px !important;
  }
}

.ant-modal-close {
  right: 1.41rem !important;
  top: 1.19rem !important;

  .ant-modal-close-icon {
    padding: 0.625rem;
    border-radius: 5rem;
    border: 1px solid #FFF;
    background: #FFF;

    svg {
      color: black;
    }
  }
}

.ant-upload.ant-upload-select {
  color: #000;
  font-family: Nunito Sans;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  opacity: 0.8;
  width: 100%;
  background-color: white;
  border: none !important;
  width: 100% !important;
  background-color: red;
}

.ant-upload-wrapper,
.ant-upload-list,
.ant-upload.ant-upload-select {
  height: 100% !important;
  margin: 0;
}

.ant-upload-list-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
}

.ant-upload-list-item {
  padding: 0 !important;
}

.ant-upload-list-item-done {
  border: none !important;
}

.ant-upload-list-item:hover::before {
  border-radius: 0.62rem;
}

.ant-upload-list-item::before {
  height: 100% !important;
  width: 100% !important;
  border-radius: 0.438rem !important;
}

.img-crop-modal {
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-title {
    display: none;
  }

  .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #000000 !important;
  }

  .ant-slider-track {
    background-color: black !important;
  }

  button.ant-btn-primary {
    height: auto;

    &:hover {
      color: unset !important;
      border-color: unset !important;
      background-color: rgb(31 42 47 / var(--tw-bg-opacity)) !important;
      color: white !important;
      border-color: rgb(31 42 47 / var(--tw-border-opacity)) !important;
    }

    @apply transition-all select-none px-3.5 py-3.7 text-lg font-bold rounded-[5rem] disabled:cursor-not-allowed outline-[3px] border-custom-gray bg-custom-gray text-white disabled:text-white-opacity-50 hover:shadow-custom-button active:bg-black
  }

  button.ant-btn-default {
    height: auto;

    &:hover {
      color: unset !important;
      border-color: unset !important;
      background-color: unset !important;
    }

    @apply transition-all select-none px-3.5 py-3.7 text-lg font-bold rounded-[5rem] disabled:cursor-not-allowed border border-black bg-white text-black disabled:text-black-opacity-50 active:outline-custom-gray active:outline active:outline-[3px] hover:shadow-custom-button focus-visible:outline-black
  }
}

.review-rate {

  .ant-rate-star-first,
  .ant-rate-star-second {
    color: rgb(255 255 255 / 6%);
  }
}

.ant-upload-list-item-actions {
  a[title="Preview file"] {
    display: none !important;
  }

  .ant-upload-list-item-action {
    height: 1.9rem !important;
    width: 1.9rem !important;

    .ant-btn-icon,
    .anticon.anticon-delete,
    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.ant-upload-list.ant-upload-list-picture-card {
  overflow: hidden !important;
}

.ant-upload-list-item-thumbnail {
  img {
    object-fit: cover !important;
    border-radius: 0.438rem;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.card-details-rate {
  svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  &.ant-rate {
    color: black;
  }
}

.profile-modal {
  padding-top: 2rem;
  padding-bottom: 2rem !important;

  .ant-modal-content {
    padding: 0;
    border-radius: 12px !important;
  }
}

// TODO: not working
:root {
  --media-playback-rate-button-display: none;
}
